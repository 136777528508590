import React, { useContext, useEffect, useRef, useState } from 'react';
import HtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { PopupContext } from 'modules/context/PopupContext';
import { palette, zIndex } from 'constants/styles';

const Wrapper = styled.div`
  pointer-events: none;
  z-index: ${zIndex.modalPopup};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupBox = styled.div`
  pointer-events: fill;
  width: 400px;
  padding: 44px 37px;
  border-radius: 10px;
  background-color: ${palette.navy[5]};
`;
const PopupTitle = styled.div`
  line-height: 29px;
  color: ${palette.primary.white};
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  em {
    color: ${palette.semantic.orange};
  }
`;
const PopupText = styled.div`
  min-height: 63px;
  margin-top: 20px;
  color: ${palette.grey[4]};
  text-align: center;
  line-height: 150%;
  white-space: pre-line;

  em {
    color: ${palette.primary.white};
    font-style: normal;
  }
`;
const PopupButton = styled.div`
  display: flex;
  margin: 40px 13px 0;
  button {
    width: 100%;
  }
  button + button {
    margin-left: 10px;
  }
`;

const Popup = ({ title, text, children }) => {

  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef();
  const popupState = useContext(PopupContext);

  useEffect(() => {
    if (popupOpen) {
      popupRef.current.focus();
    }
  }, [popupOpen]);

  useEffect(() => {
    try {
      if (title !== undefined && text !== undefined && children !== undefined) {
        setPopupOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [title, text, children]);

  return (
    <Wrapper
      ref={popupRef} popupOpen={popupOpen} onKeyDown={(e) => {
        if (e.keyCode === 27) {
          setPopupOpen(false);
          popupState.update({ display : false });
        }
      }}
      tabIndex="-1">
      <PopupBox>
        <PopupTitle>
          {title}
        </PopupTitle>
        <PopupText>
          {HtmlParser(text)}
        </PopupText>
        <PopupButton>
          {children}
        </PopupButton>
      </PopupBox>
    </Wrapper>
  );
};

export default Popup;

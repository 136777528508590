export const termsLink = {
  download: {
    termsOfCondition: {
      ko: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-ko.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-en.pdf',
    },
    privacyPolicy: {
      ko: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-ko.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-en.pdf',
    },
  },
  view: {
    termsOfCondition: {
      ko: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-ko.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-en.pdf',
    },
    privacyPolicy: {
      ko: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-ko.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-en.pdf',
    },
  }
};

export const manualLink = {
  ko: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/User+Manual_CEPPRO+Cloud_v3_kr.pdf',
  en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/User+Manual_CEPPRO+Cloud_v3_en.pdf'
};

import React from 'react';
import styled from 'styled-components';
import { palette } from 'constants/styles';

const FooterFormContainer = styled.footer`
  position: relative;
  text-align: center;
  width: 100%;
`;

const FooterLabel = styled.a`
  font-size: 12px;
  font-weight: 700;
  color: ${palette.semantic.gray[6]};
  vertical-align: top;

  :hover {
    text-decoration: underline;
  }
  & + & {
    margin-left: 20px;
  }
`;

const FooterInfo = styled.div`
  height: 14px;
  font-size: 11.5px;
  color: ${palette.grey['4']};
`;

const Copyright = styled.div`
  line-height: 13px;
  font-size: 11.5px;
  font-weight: 500;
  color: ${palette.grey['4']};
  margin: 5px 0 0;
`;

const UDISpan = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: ${palette.grey['4']};
`;

const UDIdiv = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${palette.grey['4']};
`;

const UDICode = styled.div`
  margin: 8px 0 5px 0;
`;

const Footer = ({
  links,
  info,
  copyright,
  item,
  model,
  product,
  ddhaimceppro,
  cepprocloud,
  udicode,
  dentalsoftware,
  medicalDevice,
  softwareVersion
}) => (
  <FooterFormContainer>
    {links.map((d, i) => (
      <FooterLabel key={i} href={d.link} target="_blank">
        {d.text}
      </FooterLabel>
    ))}
    <UDICode>
      <UDIdiv>
        {item}: <UDISpan>{dentalsoftware}</UDISpan> | {model}:
        <UDISpan> {cepprocloud} </UDISpan>| {product}: {ddhaimceppro} | {softwareVersion}
      </UDIdiv>
      <UDIdiv>
        UDI CODE: <UDISpan>{udicode}</UDISpan>
      </UDIdiv>
    </UDICode>
    <FooterInfo>{info}</FooterInfo>
    <Copyright>{copyright} | {medicalDevice}</Copyright>
  </FooterFormContainer>
);

export default Footer;

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { fontEN, palette, size } from 'constants/styles';
import PlusCircle from 'assets/icons/PlusCircle';
import ThinButton from 'components/button/ThinButton';
import { viewerAPI } from 'modules/api/viewerAPI';
import FormItem from 'components/forms/FormItem';
import { handleDatePicker, getDateFormat } from 'utils/functions';
import { useCookies } from 'react-cookie';
import { ToastContext } from 'modules/context/ToastContext';
import Localization from 'i18n';
import { ViewerContext } from 'modules/context/ViewerContext';

const ViewBoard = styled.div`
  padding: 50px 215px 100px;
  border-radius: 0 0 8px 8px;
  background-color: ${palette.navy['2']};
  .save {
    float: right;
  }
`;

export default ViewBoard;

export const ViewHistoryBoard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ViewHistory = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 17px;
`;

const UploadFileWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 155px;
  background-color: ${palette.background.black};
  border-radius: 5px;
  font-family: ${fontEN};
  font-weight: 500;
  font-size: 12px;
  color: ${palette.primary.blue};
  span {
    margin-top: 5px;
  }
  @media screen and (max-width: ${size.portrait}px) {
    margin-top : ${(props) => (props.state === 'Superimposition' ? '-50px' : '')} ;
    height: 155px;
  }
`;

export const UploadFile = ({ onClick }) => {

  const viewerState = useContext(ViewerContext);

  return (
    <UploadFileWrapper onClick={onClick && onClick} state={viewerState.state}>
      <PlusCircle width="32" height="32" color={palette.primary.blue} />
      <span>Add a new file</span>
    </UploadFileWrapper>
  );
};

const ViewImageButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  button + button {
    margin-top: 10px;
  }
`;

const ViewImage = styled.div`
  width: 100%;
  height: 155px;
  background-image: url(${(props) => props.src});
  background-color: ${palette.background.black};
  background-size: contain;
  border-radius: 5px;
  background-repeat: no-repeat;

  &:hover {
    ${ViewImageButtons} {
      visibility: visible;
    }
  }
`;

const ViewImageWrapper = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
`;

const ViewDateAndModify = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditButton = styled.div`
  margin-left: 6px;
  &:hover svg path {
    cursor: pointer;
    stroke: ${palette.hover.blue};
  }
`;

export const ViewImageItem = ({
  alt,
  chartInfo,
  onClick,
  changeImg,
  deleteChart
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [shootDate, setShootDate] = useState(chartInfo.shoot_date);
  const cookies = useCookies();
  const toastState = useContext(ToastContext);

  useEffect(() => {
    setShootDate(chartInfo.shoot_date);
  }, [chartInfo]);
  return (
    <ViewImageWrapper>
      <ViewImage src={chartInfo.url} alt={alt}>
        <ViewImageButtons>
          <ThinButton onClick={onClick && onClick}>{Localization.viewing_viewer}</ThinButton>
          <ThinButton theme="black" onClick={changeImg && changeImg}>
            {Localization.change_image}
          </ThinButton>
          <ThinButton onClick={deleteChart && deleteChart}>
            {Localization.delete_chart}
          </ThinButton>
        </ViewImageButtons>
      </ViewImage>
      <ViewDateAndModify>
        <EditButton
          onClick={() => {
            setDatePickerOpen(true);
          }}
        >
          <FormItem
            type="date"
            name="shoot"
            menu="superimposition"
            maxLength="30"
            value={new Date(shootDate)}
            onDateChange={(date) => {
              handleDatePicker('changeShoot', date, '', setShootDate);
              chartInfo.shoot_date = getDateFormat(date);
              viewerAPI.updateShootDate(
                {
                  chart_id: chartInfo.strapiId,
                  image_type_cd: ['00701'],
                  shoot_date: getDateFormat(chartInfo.shoot_date)
                },
                cookies
              );
              toastState.display(true);
              toastState.theme('basic');
              toastState.update({
                kind: 'success',
                title: Localization.success,
                text: Localization.success_save
              });
            }}
          />
        </EditButton>
      </ViewDateAndModify>
    </ViewImageWrapper>
  );
};

import axios from 'axios';
import { pagePaths } from 'constants/paths';
import { Cookies } from 'react-cookie';

const downCookies = new Cookies();

const defaultOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

const apiHost = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_REST_PROD
  : process.env.REACT_APP_REST_DEV;

export const axiosRefreshToken = axios.create({
  baseURL: `${apiHost}`
});

axiosRefreshToken.interceptors.request.use(
  async (config) => {
    const token = await Promise.resolve(config.data.refreshToken);
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `bearer ${token}`,
      csrf: ''
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const axiosConnection = axios.create({});
let isTokenRefreshing = false;

axiosConnection.interceptors.request.use(
  async (config) => {
    config.timeout = 30000; // timeOut 30sec
    config.meta = config.meta || {};
    config.meta.requestStartedAt = new Date().getTime();

    const downCookies2 = new Cookies();
    const token = downCookies2.get('token');
    // 요청시 AccessToken 계속 보내주기
    if (!token || config.method === 'put') {
      config.headers.accessToken = null;
      config.headers.refreshToken = null;
      return config;
    }

    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  },
  (error) => {
    return error;
  }
);

// 로그인 관련 에러 코드
const loginErrorCode = [
  'J001',
  'J005',
  'C001',
  'C002',
  'M003',
  'M005',
  'K007',
  'K006',
  'K013',
  'K016',
  'K017',
  'K022',
];

axiosConnection.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.config.method === 'put') {
        return;
      }
      if (response.data.success === true) {
        if (response.data.status === 'ok') {
          return response.data; // file delete status
        }
        return response.data.data;
      } else if (loginErrorCode.includes(response.data.error.error_code)) {
        return Promise.reject(response.data.error);
      } else {
        console.log('HTTP ERROR:', response);
        return Promise.reject(response.data.error);
      }
    } else if (response.status === 201) { // geoRedirector API
      return response.data;
    }
  },

  async (error) => {
    const {
      config,
      response
    } = error;

    const code = error.code;

    // timeout
    if (code === 'ECONNABORTED' || code === 'ECONNRESET') {
      console.log(code);
      return Promise.reject(error.code);
    }

    // if (error.error_code === 'K024') {

    // }
    const status = response.status;

    if (status === 401) {
      const originalRequest = config;
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;
        const refreshToken = downCookies.get('refreshToken');
        if (error.response.data.error.error_code === 'J002') {
          originalRequest._retry = true;

          try {
            const refresh = await axiosRefreshToken.post('/api/refresh-token', {
              refreshToken
            });
            if (refresh.status === 200) {
              downCookies.remove('token', { path: '/' });
              downCookies.remove('refreshToken', { path: '/' });
              downCookies.set('token', refresh.data.data.token);
              downCookies.set('refreshToken', refresh.data.data.refresh_token);
              isTokenRefreshing = false;
              axiosConnection.defaults.headers.Authorization = `Bearer ${refresh.data.data.token}`;
              config.headers.Authorization = `Bearer ${refresh.data.data.token}`;
              return axiosConnection(originalRequest);
            }
          } catch (error) {
            return Promise.reject(error.response.data.error);
          }
        }
      }
    } else if (status === 404) {
      return Promise.reject(response.status);
    }
  }
);

export const httpAPI = function (host, path, callOptions) {
  const options = { ...defaultOptions, ...callOptions };

  return new Promise((resolve, reject) => {
    axiosConnection(host + path, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (loginErrorCode.includes(error.error_code)) {
          if (error.error_code === 'J005') {
            alert('Duplicate Login!');
            downCookies.remove('token');
            downCookies.remove('refreshToken');
            downCookies.remove('i');
            return window.location.reload();
          }
          reject(error);
        } else if (error.error_code) {
          reject(error);
        } else if (error.message || error.code === 'ECONNABORTED' || error.code === 'ECONNRESET') {
          reject(error.message ? error.message : new Error('Request timed out')); 
        } else if (error.error_code === 'J002') {
          downCookies.remove('token', { path: '/' });
          downCookies.remove('refreshToken', { path: '/' });
          downCookies.remove('i', { path: '/' });
          window.location.replace(pagePaths.home);
        } else {
          reject(error);
        }
      });
  });
};

export const getAPI = function (host, path, callOptions) {
  const options = { mothod: 'GET', headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }, ...callOptions };

  return new Promise((resolve, reject) => {
    axiosConnection(host + path, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

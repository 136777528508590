import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ListComponent from 'components/board/ListComponent';
import { BoardHeader, AddButton } from 'components/board/BoardComponent';
import BoardBody from 'components/board/BoardBody';
import BoardFooter from 'components/board/BoardFooter';
import { pagePaths } from 'constants/paths';
import {
  GET_ALL_PATIENT_LIST_OF_DOCTOR,
  UPDATE_PATIENT_INFO
} from 'modules/queries/patients';
import IconAddUser from 'assets/icons/AddUser';
import { GlobalContext } from 'modules/context/GlobalContext';
import Localization from 'i18n';
import _ from 'lodash';
import { UPDATE_CHART_MUTATION } from 'modules/queries/chart';
import { PopupContext } from 'modules/context/PopupContext';
import Tutorial from 'components/forms/Tutorial';
import { GET_USER_PERMISSION } from 'modules/queries/user';
import { ViewerContext } from 'modules/context/ViewerContext';

const listHeadData = [
  { width: '140px', label: 'Patient ID', sortValue: 'id', sortable: true },
  { width: '100px', label: 'Name', sortValue: 'first_name', sortable: true },
  {
    width: '140px',
    label: 'Birthdate (Age)',
    sortValue: 'birth_info',
    sortable: true
  },
  { width: '70px', label: 'M/F' },
  { width: '100%', label: 'History' },
  { width: '70px', label: 'Edit' },
  { width: '70px', label: 'DELETE' }
];

const pageUnit = 10;

const ListContainer = ({ mode }) => {
  const globalContext = useContext(GlobalContext);
  const popupState = useContext(PopupContext);
  // totalcount에서 배열 push로 data 관리 중 (사용중인 state임) 
  const [pageIndex, setPageIndex] = useState([]);
  const history = useHistory();
  const { p } = useParams();
  const [searchText, setSearchText] = useState('');
  const [listData, setListData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(p);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortingItem, setSortingItem] = useState('createdAt');
  const [isSearch, setIsSearch] = useState(false);
  const [isTutorial, setIsTutorial] = useState(false);
  const viewerState = useContext(ViewerContext);

  const [deletePatientMutation] = useMutation(UPDATE_PATIENT_INFO);
  const [deleteChartsMutation] = useMutation(UPDATE_CHART_MUTATION);
  const [getAllChartListOfPatientSearch] = useLazyQuery(
    GET_ALL_PATIENT_LIST_OF_DOCTOR
  );
  const getUserPermission = useQuery(GET_USER_PERMISSION, {
    variables: {
      filters: {
        id: {
          eq: globalContext && globalContext.user && globalContext.user.user_info.id
        }
      }
    }
  });

  const handleMovePage = (path) => {
    history.push(path);
  };

  const handleClickPageNumber = (value) => {
    handleMovePage(pagePaths.list.replace(':p', value));
    setPage(value);
    if (searchText) setIsSearch(true);
  };

  const handleClickPageButton = (step) => {
    let pageNumber;
    if (searchText) setIsSearch(true);
    if (page + step < 0) {
      if (step === -1) {
        return false;
      } else if (step === pageIndex.length * -1) {
        pageNumber = 0;
      }
    } else if (step === pageIndex.length) {
      if (
        Math.floor((page + step) / pageIndex.length) * pageIndex.length
        > Math.ceil(total / pageUnit)
      ) {
        pageNumber = Math.ceil(total / pageUnit);
      } else {
        pageNumber = Math.floor((page + step) / pageIndex.length) * pageIndex.length;
      }
    } else {
      pageNumber = page + step;
    }
    handleMovePage(pagePaths.list.replace(':p', pageNumber));
    setPage(pageNumber);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSumbitSearch = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setIsSearch(true);
    }
  };
 
  const handleCreate = () => {
    if (mode === 'modal') {
      globalContext.update({ modalMode: 'create' });
    } else {
      handleMovePage(pagePaths.create);
    }
  };

  const handleClickRow = (id) => {
    if (mode === 'modal') {
      globalContext.update({ modalMode: 'view', viewPatientID: id });
    } else {
      history.push(pagePaths.viewer.replace(':id', id));
    }
  };

  const handleEditChart = (id) => {
    if (mode === 'modal') {
      globalContext.update({ modalMode: 'add', viewPatientID: id });
    } else {
      globalContext.update({
        displayModal: true,
        modalMode: 'view',
        viewPatientID: id
      });
    }
  };

  const handleSortList = (value) => {
    setSortingItem(value);

    return sortingOrder === 'desc'
      ? setSortingOrder('asc')
      : setSortingOrder('desc');
  };

  const handlePushViewerPage = (id) => {
    history.push(pagePaths.viewer.replace(':id', id));
  };

  // List에서 이미 존재하는 환자나 차트 삭제하는 것은 deletedAt으로 
  const deletePatient = (id) => {
    deletePatientMutation({
      variables: {
        updateDdhaimPatientId: id,
        data: {
          deletedAt: new Date().toISOString()
        }
      },
      onCompleted: (data) => {
        data.updateDdhaimPatient.data.attributes.ddhaim_charts.data.map(obj => {
          deleteChartsMutation({
            variables: {
              updateDdhaimChartId: obj.id,
              data: {
                deletedAt: new Date().toISOString()
              }
            },
            onCompleted: () => {
              if (total % 10 === 1) {
                window.location.reload();
              }
            },
            onError: (err) => {
              console.log(err);
            }
          });
        });

        getAllPatientListOfDoctor.refetch();
      },
      onError: (error) => {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    try {
      if (getUserPermission.data) {
        if (getUserPermission.data.ddhaimUsers.data[0].attributes.user_permission.tutorial) {
          setIsTutorial(true);
        } else {
          setIsTutorial(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [getUserPermission.data]);

  useEffect(() => {
    try {
      let currPage = p;
      if (!p || p === ':p') {
        currPage = 0;
        handleMovePage(pagePaths.list.replace(':p', currPage));
      }
      setPage(parseInt(p, 10));
    } catch (error) {
      console.log(error);
    }
  }, [p]);

  const getAllPatientListOfDoctor = useQuery(GET_ALL_PATIENT_LIST_OF_DOCTOR, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        ddhaim_user: {
          id: {
            eq: globalContext && globalContext.user && globalContext.user.user_info.id
          }
        },
        deletedAt: {
          null: true
        },
        ddhaim_charts: {
          ddhaim_chart_data: {
            image_type_cd: {
              eq: '00701'
            }
          }
        }
      },
      ddhaimChartsFilters2: {
        deletedAt: {
          null: true
        }
      },
      pagination: {
        pageSize: 10,
        page: Number(page + 1)
      },
      sort: [`${sortingItem}:${sortingOrder}`]
    },
    onCompleted: (data) => {
      if (!isSearch && searchText === '') {
        const patientListData = data.ddhaimPatients.data;
        setListData(patientListData);

        const chartHistoryPatient = [];

        const dateDescending = (a, b) => {
          if (
            a.attributes.ddhaim_chart_data.data.length === 0
            || b.attributes.ddhaim_chart_data.data.length === 0
          ) {
            return 1;
          }

          return a.attributes.ddhaim_chart_data.data[0].attributes.shoot_date
            < b.attributes.ddhaim_chart_data.data[0].attributes.shoot_date
            ? 1
            : -1;
        };

        patientListData.map((d) => {
          const pinfo = _.cloneDeep(d);

          if (d.attributes.ddhaim_charts.data.length > 1) {
            pinfo.attributes.ddhaim_charts.data.sort(dateDescending);

            chartHistoryPatient.push({
              patient_id: pinfo.id,
              history: pinfo.attributes.ddhaim_charts.data
            });
          } else {
            chartHistoryPatient.push({
              patient_id: d.id,
              history: d.attributes.ddhaim_charts.data
            });
          }
        });

        const totalCount = data.ddhaimPatients.meta.pagination.total;
        if (pageIndex.length === 0) {
          for (let i = 0; i < Math.ceil(totalCount / 10); i++) {
            pageIndex.push(i + 1);
          }
        }
        const chartUUID = [];
        chartHistoryPatient.map((e) => {
          const historyValue = e.history;
          historyValue.map((e2) => {
            chartUUID.push(e2.attributes.uuid);
          });
        });
        setHistoryData(chartHistoryPatient);
        viewerState.update({
          'chartUUID': chartUUID
        });
        setTotal(totalCount);
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });
 
  useEffect(() => {
    try {
      if (isSearch) {
        getAllChartListOfPatientSearch({
          variables: {
            filters: {
              deletedAt: {
                null: true
              },
              first_name: {
                contains: searchText
              },
              ddhaim_user: {
                email: {
                  eq: globalContext.user.user_info.email
                }
              },
              ddhaim_charts: {
                ddhaim_chart_data: {
                  image_type_cd: {
                    in: ['00701']
                  }
                }
              }
            },
            ddhaimChartsFilters2: {
              deletedAt: {
                null: true
              }
            },
            pagination: {
              start: page * pageUnit,
              limit: pageUnit
            },
            sort: [`${sortingItem}:${sortingOrder}`]
          },
          onCompleted: (data) => {
            const resultData = data.ddhaimPatients.data;
            setListData(resultData);

            const chartHistoryPatient = [];
            resultData.map((d) => {
              chartHistoryPatient.push({
                patient_id: d.id,
                history: d.attributes.ddhaim_charts.data
              });
            });

            while (pageIndex.length !== 0) {
              pageIndex.pop();
            }

            const totalCount = data.ddhaimPatients.meta.pagination.total;
            if (pageIndex.length === 0) {
              for (let i = 0; i < Math.ceil(totalCount / 10); i++) {
                pageIndex.push(i + 1);
              }
            }
            setHistoryData(chartHistoryPatient);
            setTotal(data.ddhaimPatients.meta.pagination.total);
            setIsSearch(false);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [searchText, isSearch, page]);

  return (
    <>
      <ListComponent mode={mode}>
        <BoardHeader
          theme="list"
          title={Localization.patient_list}
          onKeyPress={handleSumbitSearch}
          onChange={handleSearchTextChange}
        >
          <AddButton onClick={handleCreate}>
            <IconAddUser /> {Localization.new_patient}
          </AddButton>
        </BoardHeader>

        <BoardBody
          state={
            getAllPatientListOfDoctor.loading
              ? 'loading'
              : getAllPatientListOfDoctor.error
                ? 'error'
                : null
          }
          listHeadData={listHeadData}
          data={listData}
          historyData={historyData}
          pushViewerPage={handlePushViewerPage}
          onClick={handleClickRow}
          onEdit={handleEditChart}
          deletePatient={deletePatient}
          sortingOrder={sortingOrder}
          sortingItem={sortingItem}
          onSort={handleSortList}
        />
        <BoardFooter
          pageIndex={pageIndex}
          unit={pageUnit}
          page={page}
          total={total}
          handleClickPageNumber={handleClickPageNumber}
          handleClickPageButton={handleClickPageButton}
        />
      </ListComponent>
    </>
  );
};

export default ListContainer;

import LocalizedStrings from 'react-localization';
import en from './en.json';
import ja from './ja.json';
import ko from './ko.json';
import cn from './cn.json';

const locals = {
  'en': en,
  'ja': ja,
  'ko': ko,
  'cn': cn
};

const Localization = new LocalizedStrings(locals);

export const langsSelectList = {
  ko: (`${Localization.lang_ko}`),
  en: (`${Localization.lang_en}`),
  // cn: (`${Localization.lang_cn}`), // 중국어 비활성화 by james 2023-06-12
  // ja: (`${Localization.lang_ja}`), // 일본어 비활성화 by james 2023-03-23
};

const index = Object.keys(langsSelectList).indexOf(window.localStorage.getItem('lang'));
if (index !== -1) {
  Localization.setLanguage(Object.keys(langsSelectList)[index]);
}

export default Localization;

import React from 'react';

import Popup from 'components/common/Popup';
import DefaultButton from 'components/button/DefaultButton';

const PopupContainer = ({ state, onClear }) => {
  const { display, title, text, textCancel, textConfirm, fnCancel, fnConfirm, imgUrl, event } = state;

  const handleCancel = () => {
    if (fnCancel) fnCancel();
    onClear();
  };
  const handleConfirm = () => {
    if (fnConfirm) fnConfirm();
    onClear();
  };

  if (!display) return null;

  return (
    <Popup title={title} text={text}>
      {textCancel && <DefaultButton theme="white" onClick={handleCancel}>{textCancel}</DefaultButton>}
      {textConfirm && <DefaultButton onClick={handleConfirm}>{textConfirm}</DefaultButton>}
    </Popup>
  );
};

export default PopupContainer;
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { fontEN, palette } from 'constants/styles';
import IconCancel from 'assets/icons/Remove';
import imgPano from 'assets/img-pano.svg';

const CreateBoard = styled.div`
  padding: 50px 247px 100px;
  border-radius: 0 0 8px 8px;
  background-color: ${palette.navy['2']};
`;

export default CreateBoard;

export const UploadImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const ImageItemWrapper = styled.div`
  width: ${props => (props.size === 'small' ? '100%' : '285px')};
  height: ${props => (props.size === 'small' ? '140px' : '256px')};
`;
const Title = styled.div`
  height: 20px;
  margin-bottom: 10px;
  color: ${palette.grey['0']};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;;
`;
const ImageArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (props.size === 'small' ? '140px' : '226px')};
  border: 1px ${props => (props.theme)} #818595;
  border-radius: 5px;
  cursor: pointer;
  ${props => (props.hover && `
    background-color: rgba(43, 43, 43, 0.8);
  `)}
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 226px;
  overflow: hidden;
  background: url(${props => props.src}) no-repeat 50% 50%/cover;
  border-radius: 5px;
`;
const ImageIcon = styled.div`
  display: flex;
  align-items: center;
  // height: 100px;
  margin-bottom: ${props => (props.size === 'small' ? '10px' : '15px')};

  img {
    ${props => (props.size === 'small' && `
      height: 55px;
    `)}
  }
`;
const ImageAreaInner = styled.div`
  color: ${palette.grey['1']};
  font-size: 12px;
  text-align: center;
  font-family: ${fontEN};
  strong {
    color: ${palette.primary.blue};
  }
  span {
    display: block;
    &:last-child {
      margin-top: ${props => (props.size === 'small' ? '5px' : '9px')};
      font-size: 8px;
    }
  }
`;
const CamButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  :hover {
    background-color: rgba(255,255,255,.7);
  }
`;

const focusedStyle = {
  background: 'rgba(43, 43, 43, 0.8)'
};
const acceptStyle = {
  background: 'rgba(43, 43, 43, 0.8)'
};
const rejectStyle = {
  background: 'rgb(251 56 54 / 20%)'
};

export const ImageItem = ({ src, title, icon, children, hover, onClick, size, onChange, name }) => {
  const { getRootProps, getInputProps, 
    isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => {
        if (onChange) onChange(name || file.name, file);
      });
    }
  });
  const dropStyle = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <ImageItemWrapper size={size}>
      {!size && <Title>{title}</Title>}
      <ImageArea
        theme={src ? 'solid' : 'dashed'} hover={hover} size={size} onClick={onClick && onClick}
        {...getRootProps({ style: dropStyle })}
      >
        <input {...getInputProps()} />
        {src ? (
          <>
            <ImageWrapper src={src} />
            <CamButton onClick={onClick && onClick}><IconCancel /></CamButton>
          </>
        ) : (
          <>
            <ImageIcon size={size}>
              {icon === 'pano' && <img src={imgPano} alt="" />}
            </ImageIcon>
            <ImageAreaInner size={size}>
              {children}
            </ImageAreaInner>
          </>
        )}
      </ImageArea>
    </ImageItemWrapper>
  );
};

export const UploadForms = styled.div`
  padding: 0 0 40px;
`;
export const UploadFormTitle = styled.div`
  height: 20px;
  margin-bottom: 20px;
  color: ${palette.primary.white};
  font-weight: 700;
  font-size: 14px;
`;

export const UploadButton = styled.div`
  text-align: center;
`;

export const ViewButton = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
  margin: 20px 0 40px;

  button + button {
    margin-left: 20px;
  }
`;